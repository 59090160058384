import { css } from "@emotion/react";
import { lineHeights, spacing, fontSizes, colors, letterSpacings } from "../../core";

const ICON_WIDTH = "50px";
const ARROW_WIDTH = "28px";

export const styles = {
  root: () =>
    css({
      padding: `0 ${spacing.baseUnitMobile3}`,
    }),
  rootBorders: () =>
    css({
      border: `1px solid ${colors.disabledGrey.hex}`,
      borderRadius: "8px",
      backgroundColor: colors.white.hex,
      padding: `0 ${spacing.baseUnitMobile2}`,
      maxWidth: "712px",
      margin: `${spacing.baseUnitMobile3} auto`,
    }),
  accordionTriggerContainer: () =>
    css({
      width: "100%",
      display: "grid",
      cursor: "pointer",
      gridTemplateColumns: `${ICON_WIDTH} 1fr ${ARROW_WIDTH}`,
      gridTemplateAreas: `
      ' icon text arrow'
      `,
    }),
  accordionTriggerNoImage: () =>
    css({
      width: "100%",
      display: "grid",
      gridTemplateColumns: `1fr ${ARROW_WIDTH}`,
      gridTemplateAreas: `
        ' text arrow '
        `,
    }),
  visibleTextArea: () =>
    css({
      gridArea: "text",
      margin: `${spacing.baseUnitMobile2} 0`,
      width: "100%",
    }),
  title: (markAsNotAvailable: boolean) =>
    css({
      fontSize: fontSizes.body.horse,
      fontWeight: 600,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: markAsNotAvailable ? colors.stdTextGrey.hex : colors.headlineGrey.hex,
      wordBreak: "break-word",
    }),
  visibleText: (markAsNotAvailable: boolean, textHighlight: boolean = false) =>
    css({
      fontSize: fontSizes.body.mouse,
      color: textHighlight && !markAsNotAvailable ? colors.green.hex : colors.stdTextGrey.hex,
      fontWeight: textHighlight && !markAsNotAvailable ? 700 : 400,
      lineHeight: lineHeights.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
    }),
  imageIcon: (markAsNotAvailable: boolean) =>
    css({
      margin: `${spacing.baseUnitMobile2} 0`,
      gridArea: "icon",
      svg: {
        marginRight: spacing.baseUnitMobile2,
        width: "32px",
        height: "32px",
        path: {
          fill: markAsNotAvailable ? colors.stdTextGrey.hex : colors.linkBlue.hex,
        },
      },
    }),
  arrowIcon: (isOpen: boolean) =>
    css({
      gridArea: "arrow",
      alignSelf: "center",
      padding: "16px",
      width: "44px",
      height: "44px",
      transform: isOpen ? "none" : "rotate(-180deg)",
      transition: "transform 0.25s ease-in-out",
      position: "absolute",
      svg: {
        width: "12px",
        height: "10px",
      },
    }),
  accordionHiddenContent: (isOpen: boolean) =>
    css({
      display: "grid",
      gridTemplateRows: isOpen ? "1fr" : "0fr",
      gridTemplateColums: "",

      transition: "grid-template-rows 0.25s ease-out",
      gridTemplateColumns: `1fr`,
      gridTemplateAreas: `
        ' hiddenText '
        `,
    }),
  iconMargin: () =>
    css({
      display: "grid",
      gridTemplateColumns: `${ICON_WIDTH} 1fr ${ARROW_WIDTH}`,
      transition: "grid-template-rows 0.15s ease-out",
      gridTemplateAreas: `
          ' . hiddenText . '
          `,
    }),
  hiddenContent: () =>
    css({
      overflow: "hidden",
      gridArea: "hiddenText",
    }),
  textContainer: () =>
    css({
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: colors.headlineGrey.hex,
      fontWeight: 400,
      marginBottom: spacing.baseUnitMobile2,
      a: {
        textDecoration: "underline",
      },
      "p:last-of-type": {
        marginBottom: 0,
      },
    }),
};
