import { spacing } from "@carfax/web-ui/core";
import { css } from "@emotion/react";

export const styles = {
  root: () =>
    css({
      h2: {
        textAlign: "center",
      },
      marginBottom: spacing.baseUnitMobile3,
    }),
};
