import { css } from "@emotion/react";
import { lineHeights, spacing, mediaQuery, colors, letterSpacings, fontSizes } from "../../core";

export const styles = {
  root: () =>
    css({
      h2: {
        lineHeight: lineHeights.heading.H2.desktop,
        textAlign: "center",
      },
      maxWidth: "712px",
      margin: `${spacing.baseUnitMobile3} auto`,
      fontSize: fontSizes.body.horse,
      letterSpacing: letterSpacings.body.horse,
    }),
  arrow: () =>
    css({
      marginRight: spacing.baseUnitMobile1,
    }),
  accordionHiddenContent: (isOpen: boolean) =>
    css({
      display: "grid",
      gridTemplateRows: isOpen ? "1fr" : "0fr",
      gridTemplateColums: "",

      transition: "grid-template-rows 0.25s ease-out",
      gridTemplateColumns: " 1fr",
      gridTemplateAreas: `
      ' hiddenText '
      `,
      columnGap: spacing.baseUnitMobile3,
      [mediaQuery.tabletOrLarger]: {
        gridTemplateColumns: "minmax(0, 144px) 1fr",
        justifyItems: "left",
        cursor: "text",
        gridTemplateAreas: `
        ' . hiddenText '
        `,
      },
    }),
  hiddenContentContainer: () =>
    css({
      overflow: "hidden",
      gridArea: "hiddenText",
    }),
  textContainer: () =>
    css({
      padding: `${spacing.baseUnitMobile1} 0`,
      "p:last-of-type": {
        marginBottom: 0,
      },
      [mediaQuery.tabletOrLarger]: {
        padding: `${spacing.baseUnitMobile1} 0`,
      },
    }),
  accordionContainer: () =>
    css({
      borderRadius: "8px",
      backgroundColor: colors.white.hex,
      border: `1px solid ${colors.disabledGrey.hex}`,
      padding: spacing.baseUnitMobile2,
      paddingBottom: spacing.baseUnitMobile6,
      a: {
        textDecoration: "underline",
      },
    }),
  title: () =>
    css({
      textAlign: "center",
      lineHeight: lineHeights.heading.H3.mobile,
      [mediaQuery.largerThanMobile]: {
        lineHeight: lineHeights.heading.H2.desktop,
      },
    }),
  accordionStaticContent: () =>
    css({
      display: "grid",
      gap: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile3}`,
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      ' title '
      ' image '
      ' visibleText '
      `,
      justifyItems: "center",
      [mediaQuery.tabletOrLarger]: {
        gridTemplateColumns: "minmax(0, 144px) 1fr",
        justifyItems: "left",
        cursor: "text",
        gridTemplateAreas: `
        ' . title '
        ' image visibleText '
        `,
      },
    }),
  image: () =>
    css({
      display: "block",
      gridArea: "image",
      width: "100%",
      maxWidth: "144px",
    }),
  headText: () =>
    css({
      gridArea: "title",
      fontWeight: 600,
      letterSpacing: letterSpacings.body.horse,
    }),
  visibleText: () =>
    css({
      gridArea: "visibleText",
      letterSpacing: letterSpacings.body.horse,
      p: {
        marginBottom: 0,
      },
    }),
  linkButton: () =>
    css({
      fontWeight: 600,
      backgroundColor: colors.white.hex,
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, -38px)",
      webkitTransform: "translate(-50%, -38px)",
      mozTransform: "translate(-50%, -38px)",
      msTransform: "translate(-50%, -38px)",
      [mediaQuery.tabletOrLarger]: {
        transform: "translate(-170px, -38px)",
        webkitTransform: "translate(-170px, -38px)",
        mozTransform: "translate(-170px, -38px)",
        msTransform: "translate(-170px, -38px)",
      },
      "& > img ": {
        marginRight: spacing.baseUnitMobile1,
      },
    }),
  arrowInverted: () =>
    css({
      transform: "rotate(180deg)",
    }),
};
