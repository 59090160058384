import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ModuleAccordionCards } from "../../../contentful/PageModularModule";
import { AccordionCard } from "@carfax/web-ui/components/AccordionCard/AccordionCard";
import BasicAccordionCard from "@carfax/web-ui/components/BasicAccordionCard";

import { styles } from "./AccordionCards.style";
import { Container } from "@carfax/web-ui/elements/Container";

interface Props {
  module: ModuleAccordionCards;
}

export enum AccordionStyles {
  Basic = "basic",
  SeeMore = "see more/less",
}

const AccordionCards: React.FC<Props> = ({
  module: { header, accordionCards, accordionStyle, identifier, expanded = false },
}) => {
  return (
    <Container>
      <div css={styles.root()} id={identifier}>
        <h2>{header}</h2>
        {accordionCards.map((card) =>
          accordionStyle == AccordionStyles.Basic ? (
            <BasicAccordionCard
              key={card.title}
              title={card.title}
              hiddenText={documentToReactComponents(card.hiddenText)}
              expanded={expanded}
              withBorders
            />
          ) : (
            <AccordionCard
              key={card.title}
              title={card.title}
              image={card.image || null}
              seeMoreText={card.seeMoreText || ""}
              seeLessText={card.seeLessText || ""}
              visibleText={documentToReactComponents(card.visibleText) || ""}
              hiddenText={documentToReactComponents(card.hiddenText)}
              expanded={expanded}
            />
          )
        )}
      </div>
    </Container>
  );
};

export default AccordionCards;
