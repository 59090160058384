import React, { useState, ReactNode } from "react";
import { styles } from "./BasicAccordionCard.style";
import { Icon, Icons } from "../../elements/Icon";

export interface AccordionWithoutImageProps {
  title: string;
  hiddenText: ReactNode;
  previewAccordion?: boolean;
  imageIcon?: string;
  visibleText?: string;
  textHighlight?: boolean;
  markAsNotAvailable?: boolean;
  withBorders?: boolean;
  expanded?: boolean;
  isInnerHTML?: boolean;
}

export const BasicAccordionCard: React.FC<AccordionWithoutImageProps> = ({
  title,
  hiddenText,
  imageIcon,
  visibleText,
  textHighlight = false,
  markAsNotAvailable = false,
  withBorders = false,
  expanded = false,
  isInnerHTML = false,
}) => {
  const [isOpen, setIsOpen] = useState(expanded);

  const hasImageIcon = Boolean(imageIcon);
  return (
    <div css={[styles.root, withBorders && styles.rootBorders]}>
      <div
        aria-expanded={isOpen}
        role="button"
        tabIndex={0}
        css={[styles.accordionTriggerContainer, !imageIcon && styles.accordionTriggerNoImage]}
        onClick={() => setIsOpen(!isOpen)}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            setIsOpen(!isOpen);
          }
        }}
      >
        {hasImageIcon && <Icon icon={imageIcon} css={styles.imageIcon(markAsNotAvailable)} />}
        <div css={styles.visibleTextArea}>
          <div css={styles.title(markAsNotAvailable)}>{title}</div>
          <div css={visibleText && styles.visibleText(markAsNotAvailable, textHighlight)}>{visibleText}</div>
        </div>
        <Icon icon={Icons.arrowUp} css={styles.arrowIcon(isOpen)} />
      </div>
      <div css={[styles.accordionHiddenContent(isOpen), hasImageIcon && styles.iconMargin]} role="region">
        <div css={styles.hiddenContent}>
          {isInnerHTML ? (
            <div css={styles.textContainer} dangerouslySetInnerHTML={{ __html: hiddenText as string }} />
          ) : (
            <div css={styles.textContainer}>{hiddenText}</div>
          )}
        </div>
      </div>
    </div>
  );
};
