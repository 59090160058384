import React, { useState, ReactNode } from "react";
import { styles } from "./AccordionCard.style";
import { Image } from "../../elements/Image";
import { Icon, Icons } from "../../elements/Icon";
import { slugify } from "./utils/slugify";

export interface AccordionCardProps {
  title: string;
  visibleText: ReactNode;
  hiddenText: ReactNode;
  seeMoreText: string;
  seeLessText: string;
  image?: {
    url: string;
    title?: string;
  };
  expanded: boolean;
}

export const AccordionCard: React.FC<AccordionCardProps> = ({
  title,
  visibleText,
  hiddenText,
  image,
  seeMoreText,
  seeLessText,
  expanded = false,
}) => {
  const [isOpen, setIsOpen] = useState(expanded);
  const [slugifyTitle] = useState(slugify(title).substring(0, 50));

  return (
    <div css={styles.root}>
      <div css={styles.accordionContainer}>
        <div css={styles.accordionStaticContent}>
          {image && (
            <div css={styles.image}>
              <Image image={image} />
            </div>
          )}
          <div css={styles.headText}>{title}</div>
          <div css={styles.visibleText}>{visibleText}</div>
        </div>
        <div css={styles.accordionHiddenContent(isOpen)} role="region" id={`accordion-${slugifyTitle}`}>
          <div css={styles.hiddenContentContainer}>
            <div css={styles.textContainer}>{hiddenText}</div>
          </div>
        </div>
      </div>
      <a
        onClick={() => setIsOpen(!isOpen)}
        css={styles.linkButton}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            setIsOpen(!isOpen);
          }
        }}
        aria-controls={`accordion-${slugifyTitle}`}
        role="button"
        aria-expanded={isOpen}
        tabIndex={0}
      >
        <Icon icon={Icons.arrowUp} css={[styles.arrow, isOpen ? "" : styles.arrowInverted]} />
        {isOpen ? seeLessText : seeMoreText}
      </a>
    </div>
  );
};
